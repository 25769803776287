exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apply-js": () => import("./../../../src/pages/apply.js" /* webpackChunkName: "component---src-pages-apply-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-protest-js": () => import("./../../../src/pages/protest.js" /* webpackChunkName: "component---src-pages-protest-js" */),
  "component---src-pages-rules-js": () => import("./../../../src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */),
  "component---src-templates-driver-js": () => import("./../../../src/templates/driver.js" /* webpackChunkName: "component---src-templates-driver-js" */),
  "component---src-templates-drivers-js": () => import("./../../../src/templates/drivers.js" /* webpackChunkName: "component---src-templates-drivers-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-results-js": () => import("./../../../src/templates/results.js" /* webpackChunkName: "component---src-templates-results-js" */),
  "component---src-templates-schedule-js": () => import("./../../../src/templates/schedule.js" /* webpackChunkName: "component---src-templates-schedule-js" */),
  "component---src-templates-standings-js": () => import("./../../../src/templates/standings.js" /* webpackChunkName: "component---src-templates-standings-js" */),
  "component---src-templates-stats-js": () => import("./../../../src/templates/stats.js" /* webpackChunkName: "component---src-templates-stats-js" */)
}

